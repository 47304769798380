
@mixin boxout() {
    background-color: $white;
    border: 0.5px solid rgba(0,0,0,0.05);
    padding: 15px 25px 20px 25px;
    margin-bottom: 20px;
    box-shadow: $global-box-shadow;
    > *:last-child {
        margin-bottom: 0;
    }
}
