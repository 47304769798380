$font-path: "https://theatreworks-assets.s3.amazonaws.com/qa/fonts";

@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    src: 
        url('#{$font-path}/bebasneue-regular-webfont.woff') format('woff');
  }

  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: 
        url('#{$font-path}/raleway-variablefont_wght-webfont.woff') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: 
        url('#{$font-path}/roboto-regular-webfont.woff') format('woff');
  }