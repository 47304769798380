.c-footer {
    background-color: $footer-bg;
    color: $gray-one;
    &__inner {
        max-width: 1000px;
        margin: 0 auto;
        padding: 40px 20px;
        text-align: center;
    }
    &__details {
    }
    &__copy-text {
        background-color: #1b1b1b;
        text-align: center;
        font-size: 1.2rem;
        color: $gray-one;
        padding: 15px;
    }
    a {
        color: #dd9933;
    }
}
