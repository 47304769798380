.tn-account-create-page {
    #tn-account-register-button-register {
        font-size: 120%;
    }
}

// login block

form.tn-login-component {
    @include boxout();
    padding: 25px 30px;
    margin-bottom: 0;
}

.tn-account-login-page {
    .tn-account-login-forgot-login-link {
        color: $third-main-color;
    }
} 