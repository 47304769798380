.page-wrap {
    background-color: $page-background;
}

.content-wrap {
    padding: $padding-base-vertical $padding-large-horizontal;
}

.page-wrap .content-wrap {
    background: $white;
    padding-bottom: 50px;
    min-height: 90vh;
}

.max-width {
    max-width: $max-site-width;
    margin: 0 auto;
}
