.tn-btn-datepicker__icon-container {
	.glyphicon {
		color: $white;
	}
}

.tn-event-listing-view__results-container {
	// Calendar Title Color
	.tn-event-listing-view__results-heading {
		color: $black;
	}
	// Calendar Day Number
	.tn-events-calendar__day-number {
		color: $black;
	}
	// Calendar Heading
	.tn-events-calendar__table th, .tn-events-calendar__table thead {
		background-color: $white;
	}
}

.tn-events-calendar__day-event-list .tn-events-calendar__day-event-list-item .tn-events-calendar__event {
	color: $white;
}