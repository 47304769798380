.tn-events-detail-page .tn-event-detail {
    flex-wrap: wrap;

    .tn-event-detail__title {
        font-family: $font-family-sans-serif;
        font-size: $font-size-h1;
    }

    &__additional-events-container {
        flex: 0 1 auto;
        order: 3;
        width: unset;
        background: $white;
        padding: 1rem 1rem 1rem 0;
        margin: 0 0 1rem 0;

        .tn-additional-events__instructions {
            margin: 0 0 1rem 0;
        }
    }

    .tn-additional-events__continue-shopping-link {
        display: none;
    }

    &__main-container {
        margin-bottom: 3rem;
    }

    .tn-ticketing-mode-change__content {
        .tn-ticketing-mode-change__anchor {
            font-style: normal;
            font-size: 2rem;
        }
    }

    .tn-additional-events__controls-container {
        border-bottom: none;
    }

    .tn-syos__layout-container {
        margin: 0 0 2rem 0;
    }

    .tn-additional-events__continue-shopping-link {
        font-size: 2rem;
    }

    // syos buttons
    .tn-syos-screen-button {
        background: $first-main-color;
        color: $black;
        border: $first-main-color;
    }

    // Choose a ticket type pop up
    .tn-syos-seat-info__price-type-description {
        font-family: $paragraph-font-family;
    }
}
