.c-header {
	background-color: $header-bg;
	color: $white;
}

.c-header__container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}

.brand-logo-link img {
	max-width: 180px;
	height: auto;
	padding: 0.5rem;
	@include mq($from: tablet) {
		max-width: 250px;
	}
}

.tn-header-component {
	.tn-heading {
		font-family: $font-family-sans-serif;
	}
}

.c-back-link {
	display: block;
	margin: 1em 0 0.5em 0;
}