.tn-subnav-component {
    border-bottom: 1px solid #f6f6f6;
    padding: 10px 0 20px 0;
    flex-wrap: wrap;

    .tn-link {
        @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-base);
        @include button-variant($black, $first-main-color-light, $first-main-color-light);
    }
    .tn-logout-link, .tn-account-info-link {
        font-size: 85%;
    }
      
    #tn-subnav-promo-button {
        color: $black;
        background: $first-main-color-light;
        border: 1px solid $gray-one;
        padding: .5rem 1rem;
        line-height: 1.5em;
        text-decoration: none;
        transition: background-color .25s ease-out,color .25s ease-out;
        &:focus {
            background: $brand-success;
            border:  1px solid $brand-success;
        }
    }

    .tn-promo-box {
        @include mq($until: mobile) {
            margin: 10px 0 0 10px;
        }
    }
    #tn-apply-promo {
        display: flex;
        justify-content: flex-end;
    }
    
    #tn-subnav-promo-code {
        flex: 0 1 40px;
        border: 1px solid $gray-one;
        border-right: 0;
        padding: .5rem 1rem;
    }


}