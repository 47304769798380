.tn-event-listing-mode-tab-nav__list {
    font-family: $paragraph-font-family;
    .tn-event-listing-mode-tab-nav__list-item {
        color: $black;
    }
}

// Month picker
.tn-btn-datepicker__btn-container {
    .tn-btn-datepicker__btn {
        // font-family: $font-family-sans-serif-secondary;
        background: $black;
        color: $white;
        border: transparent;
        transition: background-color .25s ease-out,color .25s ease-out;
        &:hover,
        &:focus {
            background: $first-main-color;
            color: $white;
        }
    }
    // Previous & Next Buttons
    .tn-btn-datepicker__btn-period-prev-next--btn-prev, 
    .tn-btn-datepicker__btn-period-prev-next--btn-next {
        background: $first-main-color;
        color: $black;
        border-radius: 0;
        border: transparent;
        transition: background-color .25s ease-out,color .25s ease-out;
        &:active,
        &:focus {
            background: $first-main-color;
            color: $black;
        }
    }
}

// Calendar styles
// Event button
.tn-events-calendar__day-event-list {
    .tn-events-calendar__day-event-list-item {
        .tn-events-calendar__event {
            color: $black;
            font-family: $paragraph-font-family;
        }
    }
}

.tn-events-calendar__table-container {
    .tn-events-calendar__table {
        border-collapse: collapse;
        .tn-event-listing-view__results-heading {
            color: $gray-light;
        }
        .tn-events-calendar__day-cell {
            border: 1px solid $white;
            border-collapse: collapse;
            // Month day number
            .tn-events-calendar__day {
                .tn-events-calendar__day-number {
                    color: $black;
                    font-family: $paragraph-font-family;
                }
            }
        }
    }
}