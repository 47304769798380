.tn-cart-details-page {
    margin: 5rem 0 0 0;
    .tn-cart-buttons__primary-action {
        @include mq($until: tablet) {
            width: 100%;
        }
    }
    .tn-cart-buttons {
        @include mq($until: tablet) {
            text-align: left;
        }
    }
    .tn-performance-title .tn-cart-line-item-name {
        font-family: $font-family-sans-serif;
    }
}

.c-continue-shopping {
    min-width: 250px;
    label {
        font-size: 90%;
        margin-bottom: 0.5em;
    }
}

.tn-timer {
    color: $second-main-color;
    float: right;
}

.tn-timer-description {
    font-weight: bold;
}

.tn-count-down-timer {
    background: $second-main-color;
    color: $white;
    padding: .5rem 2rem;
}

.tn-syos-mobile-cart-controls .tn-syos__btn-add-to-cart {
    background: $first-main-color;
    border: $first-main-color;
    color: $black;
}